.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1px;
  position: relative;
  .label {
    margin-bottom: 0px;
  }
  .input {
    padding: 12px;
    border: 1px solid #dde4f0;
    height: 30px;
    border-radius: 10px !important;
  }
  .error-wrapper {
    min-height: 18px;
    font-size: 12px;
  }
  .textarea {
    height: 100px;
  }
  &.disabled {
    .input {
      background-color: #eef4f8;
    }
  }
  .password-show {
    position: absolute;
    right: 2px;
    top: 24px;
  }
}
