.container-auth {
  margin: 0px;
  padding: 0px;
  width: 100%;

  @media (min-width: map-get($map: $grid-breakpoints, $key: sm)) {
    padding: 0px;
  }

  @media (min-width: map-get($map: $grid-breakpoints, $key: md)) {
    padding: 0px;
  }

  @media (min-width: map-get($map: $grid-breakpoints, $key: lg)) {
    width: 660px;
    max-width: 660px;
    margin: 0px auto;
    padding: 35px 0px;
  }

  @media (min-width: map-get($map: $grid-breakpoints, $key: xl)) {
    width: 740px;
    max-width: 740px;
    margin: 0px auto;
    padding: 40px 0px;
  }

  @media (min-width: map-get($map: $grid-breakpoints, $key: xxl)) {
    width: 800px;
    max-width: 800px;
    margin: 0px auto;
    padding: 45px 0px;
  }
}