.ti {
  &-bg {
    &-pblue {
      &-900 {
        background-color: $premium900 !important;
      }
      &-700 {
        background-color: $premium700 !important;
      }
      &-500 {
        background-color: $premium500 !important;
      }
      &-200 {
        background-color: $premium200 !important;
      }
      &-100 {
        background-color: $premium100 !important;
      }
    }
  }
  &-border {
    &-pblue {
      &-900 {
        border-color: $premium900 !important;
      }
      &-700 {
        border-color: $premium700 !important;
      }
      &-500 {
        border-color: $premium500 !important;
      }
      &-200 {
        border-color: $premium200 !important;
      }
      &-100 {
        border-color: $premium100 !important;
      }
    }
  }
  &-text {
    &-pblue {
      &-900 {
        color: $premium900 !important;
      }
      &-700 {
        color: $premium700 !important;
      }
      &-500 {
        color: $premium500 !important;
      }
      &-200 {
        color: $premium200 !important;
      }
      &-100 {
        color: $premium100 !important;
      }
    }
  }
  &-d {
    &-absolute {
      position: absolute !important;
    }
    &-fixed {
      position: fixed !important;
    }
    &-relative {
      position: relative !important;
    }
    &-static {
      position: static !important;
    }
    &-sticky {
      position: sticky !important;
    }
  }
}

.fade-out-gradient {
  -webkit-mask-image: -webkit-gradient(linear, left top, 
    left bottom, from(rgba(0,0,0,0.3)), to(rgba(0, 0, 0, 0)));
}

.premium-gradient {
  background: 
  linear-gradient(130.68deg, #FFFFFF 34.95%, rgba(255, 247, 235, 0) 70.8%, rgba(255, 191, 94, 0.15) 96.09%);
  
}