.confirm-register {
    background: #fff;
    width: clamp(320px, 80vw, 600px);
    height: min-content;

    &-header {
        text-align: center;
        padding: 30px 10px;
    }
    &-part {
        padding: 15px 40px;
        background: #f8f8f8; 
    } 
    &__form-footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
    }
}