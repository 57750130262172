@import "./inputs.scss";
@import "./button.scss";
@import "./checkbox.scss";
@import "./date-picker.scss";

.form-line {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    // border: 1px solid red;
    height: 37px;
    .input {
        height: 37px;
        width: 100%;
        &:focus {
            outline: none;
            border-color: darken(#dde4f0, 10);
        }
    }
    &.big {
        height: 41px;
        .input {
            height: 41px;
        }
        .btn {
            white-space: nowrap;
        }
    }
}
