.slide-popup-wrapper {
    width: 1px;
    height: 1px;
    position: fixed;
    
    .slide-popup__frame {
        width: min-content;
    }

    &_close {
        position: absolute;
        top: 5px;
        right: 5px;
        background: transparent;
        border: 0;
        padding: 0px;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #fff;

    }

    &.top {
        top: 0;
    }
    &.bottom {
        bottom: 0;
    }
    &.left {
        left: 0;
    }
    &.right {
        right: 0;
    }
}
