.error-404 {
    min-height: calc(100vh - 110px);
    background: url("../../public/static/404.jpg");
    background-size: cover;
    background-position: center, center;
    color: white;
    text-align: center;
    overflow: hidden;
    .error-info {
        margin-top: 100px;
        font-size: 52px;
        font-weight: 700;
    }
    .error-info-homepage {
        font-size: 32px;
        transition: all .3s;
        &:hover {
            color: #f0f0ff;
            transform: scale(0.9);
        }
    }
}
