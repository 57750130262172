.after-register {
    background: #fff;
    width: clamp(320px, 80vw, 600px);
    height: min-content;

    &-header {
        text-align: center;
        padding: 30px 10px 10px 10px;
    }
    &-part {
        padding: 15px 40px;
        background: #f8f8f8; 
    } 
    &_body {
        text-align: center;
        padding: 10px;
        .email-icon {
            padding: 0px;
            margin: 0px auto 10px auto;
            // border: 2px solid $mainColor;
            border-radius: 2px;
            width: 80px;
            height: 80px;

            display: flex; 
            align-items: center;
            justify-content: center;
            .icon {
                font-size: 40px;
                line-height: 0;
                color: $mainColor;
            }
        }

        &_title {
            position: relative;
            padding-bottom: 10px;
            margin-bottom: 10px;
            &:before {
                content: '';
                position: absolute;
                height: 2px;
                width: 100px;
                bottom: 0;
                left: calc(50% - 50px);
                background: #eee;
            }
        }
    }
  
}