body .wp-caption {
  text-align: center;
  .wp-caption-text {
    font-size: 12pt;
    font-style: italic;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  img {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
  &.alignnone{
    display: inline-block;
  }
  &.alignleft{
    float: left;
    margin-right: 1rem;
  }
  &.alignright{
    float: right;
    margin-left: 1rem;
  }
  &.aligncenter{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

body :not(.wp-caption) > img[class*="wp-image-"] {
  &.alignnone{
    // width 100%
    display: inline-block;
  }
  &.alignleft{
    width: auto;
    float: left;
    margin-right: 1rem;
  }
  &.alignright{
    width: auto;
    float: right;
    margin-left: 1rem;
  }
  &.aligncenter{
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}