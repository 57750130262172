.user-card {
  background: white;
  padding: 1px 0;

  time {
    display: block;
  }

  // Responsive Styles
  @media only screen and (min-width: 992px){

    padding: 15px;

    .post-author {
      width: 100%;
      .post-author-info {
        margin-left: 0 !important;
      }

      .link-user {
        width: 100%;
      }

      img {
        margin: 0 auto;
        display: block;
      }
    }

    .tags span {
      margin: 0 0 0.5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }

    time {
      margin-bottom: 1rem;
    }
  }
}
