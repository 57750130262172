.article-intro-view {
    display: flex;
    flex-direction: row;
    &_photo {
        width: 176px !important;
        min-width: 176px !important;
        max-width: 176px !important;
        overflow: hidden;
        height: min-content;

    }
    &_text {
        padding-left: 15px;
        font-size: 14px;
    }
    .zoomable {
        transform: scale(1);
        transition: transform .3s; 
    }
    &:hover {
        .zoomable {
            transform: scale(1.1);
        }
    }
}

@media (max-width: map-get($grid-breakpoints, xxl )) {
    .article-intro-view {
        &_photo {
            width: calc(120px + 1vw) !important;
            min-width: calc(120px + 1vw) !important;
            max-width: calc(120px + 1vw) !important;
        }
        &_text {
            padding-left: 10px;
            line-height: 1.3;
        }
    } 
}