.darken {
  // filter: brightness(99%);
}

.cover-img-wrapper {
  overflow: hidden;
  border-radius: 15px;

  &.img-zoom {
    @include zoomable;
  }
}

.cover-img {
  object-fit: cover;
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  .cover-img {
    filter: brightness(90%);
  }
}

.video-play {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  background-color: $textColor;
  top: 0;
  left: 0;
  display: none;
  background-color: unset;

  .play-icon {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    color: #fff;
  }

  &.pl {
    background-color: unset;
  }

}

.cover-wrapper {
  position: relative;
  border-radius: 15px;

  &.videos {
    .video-play {
      display: flex;
    }
  }

  .live-label {
    display: none;
  }

  .interview-label,
  .podcast-label,
  .premium-label {
    display: none;
  }

  .playback-svg {
    display: none;
  }

  &.live {
    .live-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 2px;
      position: absolute;
      top: 20px;
      left: 20px;
      line-height: 1;
      padding: 3px;
      font-weight: 700;
      color: #fff;

      .live-dot {
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border-radius: 999px;
        background: #f00;
        animation: pulse 3s infinite ease-in-out;
      }
    }
  }

  &.interview {
    .interview-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: $orange500;
      position: absolute;
      bottom: 20px;
      left: 0;
      line-height: 24px;
      padding: 8px 30px 8px 20px;
      font-weight: 100;
      font-size: 20px;
      color: #fff;
      clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
    }
  }

  &.podcast {
    .podcast-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: $orange500;
      position: absolute;
      bottom: 20px;
      left: 0;
      line-height: 24px;
      padding: 8px 30px 8px 20px;
      font-weight: 100;
      font-size: 20px;
      color: #fff;
      clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
    }
  }

  &.playback {
    .playback-svg {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      svg {
        height: 175px;
        width: 175px;
        color: transparent;
      }
    }
  }

  &.premium {
    .premium-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 22px;
      background: $orange500;
      position: absolute;
      top: 10px;
      left: 10px;
      line-height: 12px;
      padding: 1px;
      font-weight: 700;
      font-size: 10px;
      color: #fff;

      svg {
        width: 13px;
        height: 11px;
      }

      span {
        margin-left: 4px;
      }
    }

    &.compact-badge {
      span {
        display: none;
      }
    }
  }
}

.article-intro-view {
  .cover-wrapper {
    &.live {
      .live-label {
        top: 5px;
        left: 5px;
      }
    }
  }
}

@media screen and (max-width: map-get($map: $grid-breakpoints, $key: 'md')) {
  .playback-svg {
    display: block;
    position: absolute;
    width: 89px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      width: 90px;
      height: 90px;
    }
  }
}