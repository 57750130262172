// -> DARKMODE
$textBlur: 5px;

.darkmode {
  background-color: $darkModeBackground;
  --background-color: #{$darkModeBackground};
  color: white;

  .lang-selector {
    color: white;
  }

  .promoted-wrapper {
    background-color: $darkModeLightBackground;

    .promoted-article_title {
      color: $darkModeTextGray !important;
    }

    &.pl {
      border-color: #3076d6 !important;
    }
  }

  .menu-list .menu-item.active {
    background-color: $premium900;
    color: white;

    &:hover {
      background-color: $premium700;
    }
  }

  .section-title {
    color: $darkModeTextGray;
  }

  .article-intro-view_text {
    color: $darkModeTextGray;
  }

  // .thumbnail {
  //   background-color: $orange100;
  // }

  .author {
    &-arrow {
      color: $darkModeOrange;
    }

    &-section_wrapper {
      background-color: $darkModeLightBackground;

      .name {
        color: $darkModeTextGray;
      }
    }

    &-top-card {
      background-color: $darkModeLightBackground;
    }

    &-normal-card {
      background-color: $darkModeLightBackground;
      color: $darkModeTextGray;
    }
  }

  .newsletter-divider {
    .email {
      &-form {
        border: 1px solid $darkModeInputBackground !important;
      }

      &-form,
      &-input {
        ::placeholder {
          color: $darkModeTextGray !important;
        }

        background-color: $darkModeDarkGray !important;
      }
    }
  }

  .recommended-wrapper--slick {
    background-color: $darkModeBackground;
    border-radius: 15px;

    .slick-list,
    .slick-slider {
      background-color: $darkModeLightBackground;
      border-radius: 15px;
    }

    .recommended-article {
      border-color: transparent;
    }

    .slick-dots li button {
      background-color: $darkModeGray !important;
      transition: background-color 0.4s;
    }
  }

  .author-intro {
    &-colorscheme {
      &-main {
        .author-info_name {
          color: $orange200 !important;
        }
      }

      &-recommended {
        .author-info_name {
          color: $orange500 !important;
          white-space: break-spaces !important;
        }

        .author-info_caption,
        .author_date {
          color: white !important;
          white-space: break-spaces !important;
        }
      }
    }
  }

  //$ AUTHORS PAGE START
  .authors {
    &-page-intro {
      color: $darkModeTextGray !important;
    }

    &-top {
      &-grid {
        color: $darkModeTextGray !important;
      }
    }

    &-wrapper {
      background-color: $darkModeBackground;
      color: $darkModeTextGray !important;

      button {
        color: $darkModeTextGray !important;
      }

      .active {
        color: $darkModeOrange !important;
      }
    }

    &-section_info {
      .name {
        color: $darkModeOrange !important;
      }

      .caption {
        color: $darkModeTextGray !important;
      }
    }
  }

  //$ AUTHORS PAGE END

  .regulations,
  .custom-container {
    color: $darkModeText !important;

    span {
      color: $darkModeTextGray !important;
    }

    a {
      color: $darkModeOrange;
    }
  }

  .register-btn {
    color: $darkModeTextGray !important;
  }

  .contact {
    &-head {
      background-color: $darkModeLightBackground !important;
    }

    &-info {
      color: $darkModeTextGray !important;
    }
  }

  .recommended-article {
    background-color: $darkModeLightBackground !important;
    color: $darkModeTextGray;
    border-color: $darkModeGray;

    .author {
      &-intro {
        &-wrapper {
          background-color: $darkModeLightBackground !important;
          color: $darkModeTextGray;
        }
      }

      &-info {
        &_name {
          color: $darkModeOrange;
        }

        &_caption {
          color: $darkModeTextGray;
        }
      }
    }
  }

  @media (max-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
    .article-cover {
      .author-intro {
        &-wrapper {
          background-color: $darkModeLightBackground !important;

          .author_date {
            background: $darkModeLightBackground !important;
            color: $darkModeText;
          }

          .author-info_caption {
            color: $lightGray !important;
          }
        }
      }
    }

    .author-intro-colorscheme {
      &-standard {
        .author-info_name {
          color: $orange500 !important;
        }
      }
    }

    .lang-selector {
      background-color: transparent;
    }
  }

  // $ POPUP START

  .popup {
    div {
      background-color: $darkModeLightBackground !important;
      border-color: $darkModeOrange;
      color: $darkModeTextGray !important;
    }

    &-close {
      color: $darkModeTextGray;
    }
  }

  // $ POPUP END

  // $ ARTICLE
  article p strong {
    font-weight: 800;
  }

  .article-content {
    &__title {
      color: $darkModeText;
    }

    &__subtitle {
      color: $darkModeText;

      a {
        color: $orange500;
      }
    }

    &__reading-time {
      color: $orange500;
    }

    &__share {
      color: white !important;

      span {
        border-color: white !important;
      }
    }

    &__percentage {
      color: white;
      text-shadow: 0px 0px 10px $darkModeBackground;
    }

    table {
      background-color: $darkModeLightBackground;

      tr:first-child td,
      tr:first-child th {
        background-color: $darkModeLightBackground;
      }

      td,
      tr {
        background-color: $darkModeBackground;
        border-color: $darkModeTextGray;
        color: $darkModeTextGray;
      }
    }

    .author {
      &_date {
        color: $darkModeText !important;
        border-color: $darkModeText !important;
      }

      &-info {
        &_name {
          color: $orange500 !important;
        }

        &_caption {
          color: $darkModeTextGray !important;
        }
      }
    }

    &__body {
      color: $darkModeText !important;

      .redaction {
        background-color: $darkModeLightBackground;
      }

      code {
        color: $darkModeText;
      }

      a {
        color: $orange500;
      }

      &.protected {
        text-shadow: 2px 0 $textBlur rgba(255, 255, 255, 0.5),
          0 2px $textBlur rgba(255, 255, 255, 0.5),
          -2px 0 $textBlur rgba(255, 255, 255, 0.5),
          0 -2px $textBlur rgba(218, 171, 96, 0.5);

        &:before {
          -webkit-box-shadow: inset 0px 0px 5px 30px $darkModeBackground !important;
          -moz-box-shadow: inset 0px 0px 5px 30px $darkModeBackground !important;
          box-shadow: inset 0px 0px 5px 30px $darkModeBackground !important;
        }
      }
    }

    .for-logged_wrapper {
      background-color: $darkModeLightBackground;

      .addnotation {
        color: $darkModeText !important;
      }
    }

    &__speech-button,
    .comments_title,
    .comments_share-title {
      color: white !important;
    }

    .add-comment {
      &_form_input {
        background-color: transparent;
        color: white !important;
        border-color: white;
      }

      &_profile {
        color: white;

        a {
          color: $orange500;
        }
      }
    }

    .comment {
      &_content {
        color: $darkModeTextGray !important;

        &__authorName {
          color: $darkModeText;
        }

        &__answers {
          color: $darkModeTextGray !important;
        }
      }

      &_footer {
        .btn {
          color: $darkModeTextGray !important;
        }
      }
    }

    &_protected-banner {
      background-color: $orange700;
    }
  }

  // $ ARTICLE END

  // $ MAGAZINE START
  .magazine {

    &-title-section-pl,
    &-title-section-de,
    &-title-section-lt {
      background-image: url('/static/magazine/background-darkmode.webp');
      border-bottom: 1px solid $darkModeBlue;
    }

    &-title-header {
      color: $darkModeTextGray;
    }

    &-icons-list-item {
      color: $darkModeTextGray;
    }

    &-footer-section,
    &-section {
      background-color: $darkModeBackground;

      &.bg-alt {
        border-top: 2px solid #f08559;
        background-color: $darkModeLightBackground;
      }

      &-title {
        background-color: $darkModeLightBackground;
        color: white;

        &.alt {
          background-color: #f08559;
          color: white;
          border: none;
        }
      }
    }

    &-box {
      &__distinct {
        background-color: $darkModeOrange;

        h2,
        p {
          color: $darkModeText;
        }

        button {
          background-color: $darkModeBlue;
        }
      }

      &__normal {
        background-color: $darkModeGray;

        h2,
        p {
          color: $darkModeDarkGray;
        }

        button {
          background-color: $darkModeOrange;
        }
      }
    }

    &-contact {
      background-color: $darkModeBackgroundGray;

      .text-orange {
        color: $darkModeBlue;
        font-weight: 800;
      }

      &-person {
        background-color: #b2b7bd;
      }

      &-divider {
        border-color: $darkModeDarkGray;
      }

      &-form {

        input,
        textarea {
          background-color: $darkModeInputBackground;
          color: black;
        }
      }
    }

    &-footer {
      background-color: $darkModeBlue;
      color: $darkModeTextGray;
    }
  }

  // $ MAGAZINE END

  // $ WEBINARS START
  .webinar {
    &-header {
      &-info {
        color: $darkModeTextGray;
      }

      &-hero {
        background-color: $darkModeLightBackground;
        border: 2px solid $darkModeOrange;

        p,
        h4,
        h3 {
          color: $darkModeTextGray;
        }

        h2,
        a {
          color: $darkModeOrange;
        }

        button {
          background: linear-gradient(90deg, #ec8054 0%, #ba4c20 100%);
        }

        &-people-presenter {
          p {
            color: $darkModeOrange;
          }
        }

        &-gradient-date {
          color: white;
        }
      }
    }

    &-numbers {
      &-title {
        color: $darkModeTextGray;
        background-color: $darkModeLightBackground;
      }

      &-bg {
        background-color: $darkModeLightBackground;
      }

      .counter-webinar {
        box-shadow: none;
        background-color: $darkModeBackground;

        h3 {
          color: $darkModeTextGray;
        }
      }
    }

    &-list {
      &-title {
        border: none;
      }

      &-box {
        background-color: $darkModeLightBackground;
        border: none;

        h3 {
          color: $darkModeOrange;
        }

        p {
          color: $darkModeTextGray;
        }

        .webinar-button {
          background: linear-gradient(90deg, #ec8054 0%, #ba4c20 100%);
        }
      }
    }

    &-contact {
      &-bg {
        background-color: $darkModeLightBackground;
      }

      &-info {
        color: $darkModeTextGray;
        background-color: $darkModeBackground;
        border-color: $darkModeLightBackground;
      }
    }

    &-footer-section {
      background-color: $darkModeLightBackground;
    }
  }

  // $ WEBINARS END

  // $ NEWSLETTER START`
  .newsletter {
    &-form {
      background-color: $darkModeBlue;

      h3,
      p {
        color: $darkModeTextGray;
      }

      input {
        background-color: $darkModeInputBackground;
        color: $darkModeTextGray;
      }
    }
  }

  // $ NEWSLETTER END
  div.profile-side {
    background-color: $darkModeLightBackground;

    div.profile-user-menu {
      div.user-menu_dropdown-item {
        color: white;
      }
    }
  }

  label.input-group.checkbox.normal div.checkbox-custom {
    border-color: white;

    svg.tick {
      color: $orange500;
    }
  }

  .profile-user-menu,
  .user-profile-item {
    color: white !important;
  }

  .user-tile-data {
    div.section-title {
      background-color: $darkModeLightBackground;
    }

    .input {
      border-color: $greytxt;
      background-color: $darkModeInputBackground;
      color: #000;
    }
  }
}

// -> DARKMODE END