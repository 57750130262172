.author-page {
    .author-header {
        background: url("/static/authorBg.png");
        background-size: cover;
        //  background-attachment: fixed;
        padding: 56px 0;
        color: $white;
        font-size: 17px;
        .author-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            &_text {
                margin-left: 25px;
                p {
                    margin-bottom: 0;
                }
                .name {
                    font-size: 29px;
                    font-weight: 600;
                }
            }
        }
        .author-desc {
            margin-top: 25px;
            margin-bottom: 25px;
        }
        .author-footer {
            display: flex;
            flex-direction: row;
            align-items: center;
            &_socials {
                margin-right: 40px;
                display: flex;
                flex-direction: row;
                .circle {
                    margin-right: 5px;
                }
                a {
                    &:hover {
                        color: unset;
                    }
                }
            }
            &_arts {
                color: $white;
                margin-right: 40px;
                .paper-icon {
                    transform: rotate(180deg) scaleX(-1);
                    color: $white;
                    margin-right: 15px;
                    display: inline-block;
                }
            }
            &_date {
                color: #b8b8b8;
            }
        }
    }

    .circle {
        border-radius: 10000px;
        padding: 2px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f3f3f3;
        color: #015c92
    }

    .social.color {
        color: #53a7d8;
        background: #bbdeff
    }

    .social.color.facebook {
        background: #3b5998
    }

    .social.color.linkedin {
        background: #007bb5
    }

    .social.color.twitter {
        background: #55acee
    }

    .social.color.www {
        background: #4a4a4d
    }

    .social.color.xing {
        background: #126567
    }

    .social.color.whatsapp {
        background: #25d366
    }

    .social.color.messenger {
        background: #0084ff
    }

    .social.color.viber {
        background: #59267c
    }

    .social.color.telegram {
        background: #08c
    }

    .social.color.vk {
        background: #45668e
    }
}

@media (max-width: map-get($map: $grid-breakpoints, $key: md )) {
    .author-page {
        .author-header {
            .author-footer {
                flex-direction: column;
                justify-content: center;
                &_socials {
                    margin-right: 0px;
                }
                &_arts {
                    margin-right: 0px;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
