.social-tile {
    // background:
    // height: 330px;
    position: relative;
    overflow: hidden;
    height: min-content;
    background-color: #1877f2;
    .bg-image {
        // width: 100%;
        // height: 260px;
    }
    &_info {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        color: $white;
        padding: 20px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        &_text {
            font-size: 14px;
            @media (min-width: 1500px) {
                font-size: 22px;
            }
            font-weight: 600;
            line-height: 1.5;
            margin-left: 5px;
            margin-right: 4vw;
        }
    }
    .social-btn {
        background: #fff;
        padding: 5px 5px 5px 24px;
        display: flex;
        align-items: center;
        margin-top: 15px;
        span {
            margin-right: 15px;
        }
        &.fb {
            color: #1877f2;
        }
        &.li {
            color: #0e76a8;
        }
    }
}
