.policy {
  font-weight: 600;
  font-size: 22px;
  text-transform: uppercase;
  ol {
    font-weight: 300;
    padding-left: 2px;
    font-size: 15px;
    text-transform: none;
    li {
      margin-left: 18px;
      padding-left: 2px;
      margin-bottom: 7px;
      line-height: 1.3;
    }
  }
  ol {
    li:empty {
      display: none;
    }
  }
  ol:empty {
    display: none;
  }
  a {
    color: $textColor;
  }
  &-regular-font {
    font-weight: 300;
    font-size: 15px;
    text-transform: none;
  }
  &-bold-underline-font {
    font-weight: 600;
    font-size: 15px;
    text-transform: none;
    text-decoration: underline;
  }
  &-bullet-list {
    list-style: circle;
  }
  &-cookie-link {
    color: #d14900;
    text-align: center;
  }
}

.new-policy {
  margin-bottom: 100px;
  h3 {
    font-size: 22px;
    font-weight: 600;
  }
  p {
    font-size: 15px;
    font-weight: 300;
    margin-left: 18px;
  }
  li {
    font-size: 15px;
    font-weight: 300;
    margin-left: 36px;
  }
  ul {
    list-style: inside;
  }
  a {
    color: $darkModeOrange;
  }
  .divider {
    border-top: 1px solid black;
    width: 40%;
  }
  .signature {
    margin-top: 10px;
    font-size: 12px;
  }
}
