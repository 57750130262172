.section-title {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 27px;
  line-height: 1;
  font-weight: 500;
  color: $textDark;
  a {
    color: unset;
  }
  .title-dot {
    display: block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    margin-bottom: 2px;
    &.main {
      background: $darkModeOrange;
    }
  }
  &.border-bottom {
    border-bottom: 1px solid $lightGray;
    padding-bottom: 13px;
  }
  &.text-light {
    color: $white;
    a {
      color: $white;
    }
  }
}

@media (max-width: map-get($grid-breakpoints , sm )) {
  .section-title {
    &.border-bottom {
      border-bottom: none !important;
      padding-bottom: 3px;
    }
  }
}
