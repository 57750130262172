.register {
    background: #fff;
    width: clamp(320px, 80vw, 600px);
    height: min-content;

    &-header {
        text-align: center;
        padding: 30px 10px;
    }
    &-part {
        padding: 15px 40px;
        background: #f8f8f8; 
    } 
    &-policy {
        font-size: 13px;
        line-height: 1.2;
        a {
            color: $textColor;
        }
    }
}