.magazine {
  &-section {
    position: relative;
    background-color: rgba(251, 251, 251, 1);
    padding: 4rem 0;
    width: 100%;

    &.bg-alt {
      border-top: 2px solid #f08559;
      background-color: #fce7de;
    }

    &-title {
      text-transform: uppercase;
      border: 2px solid #f08559;
      background-color: white;
      color: #395778;
      width: max-content;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 1.5rem 3rem;
      border-radius: 50px;
      font-size: 22px;
      font-weight: 700;

      &.alt {
        background-color: #f08559;
        color: white;
        border: 3px solid white;
      }
    }
  }
  &-icons-list {
    display: flex;
    flex-direction: row;
    column-gap: 60px;
    row-gap: 0;
    justify-content: space-between;
    &-item {
      display: flex;
      column-gap: 10px;
      align-items: center;
      p {
        margin-bottom: 0;
        font-size: 20px;
      }
      img {
        width: 40px;
        height: 40px;
        aspect-ratio: 1;
      }

      @media (max-width: map-get($map: $grid-breakpoints, $key: md )) {
        p {
          margin-bottom: 0;
          font-size: 16px;
        }
        img {
          width: 30px;
          height: 30px;
          aspect-ratio: 1;
        }
      }
    }

    @media (max-width: map-get($map: $grid-breakpoints, $key: xl )) {
      flex-direction: column;
      row-gap: 16px;
      column-gap: 0;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 2rem;
    // @media (min-width: map-get($map: $grid-breakpoints, $key: lg)) {
    //   margin-bottom: -88px;
    //   padding: 1rem;
    // }
  }

  &-grid {
    max-width: 1248px;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 45px;

    @media (min-width: map-get($map: $grid-breakpoints, $key: md )) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: map-get($map: $grid-breakpoints, $key: xxl )) {
      grid-template-columns: repeat(3, 1fr);
    }
    &-1 {
      max-width: 1248px;
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 45px;
    }
  }

  &-divider {
    margin: 0 auto;
    grid-column: 1/4;
    width: 120px;
    border-bottom: 2px solid rgba(207, 207, 207, 1);
    @media (max-width: map-get($map: $grid-breakpoints, $key: xxl)) {
      grid-column: 1/3;
    }
    &-1 {
      grid-column: 1/4;
      width: 100%;
      border-bottom: 2px solid rgba(207, 207, 207, 1);
      margin: 0 auto 120px auto;
      @media (max-width: map-get($map: $grid-breakpoints, $key: xxl)) {
        grid-column: 1/3;
      }
      @media (max-width: map-get($map: $grid-breakpoints, $key: md)) {
        grid-column: 1/2;
        width: 120px;
        margin: 30px auto;
      }
    }
  }
  &-title {
    &-header {
      font-size: 40px;
      line-height: 42px;
      span {
        font-size: 55px;
        font-weight: 700;
      }
      @media (max-width: map-get($map: $grid-breakpoints, $key: md )) {
        font-size: 28px;
        span {
          font-size: 32px;
        }
      }
    }
    &-icon-list {
      display: flex;
    }

    &-section {
      background: #fff url('/static/magazine/background.webp') center
        center/cover no-repeat;
      padding: 70px 0;
      @media (max-width: map-get($map: $grid-breakpoints, $key: md )) {
        padding: 37px 0 87px 0;
        margin-bottom: 40px;
      }
      &-pl {
        background: #fff url('/static/magazine/background.webp') center
          center/cover no-repeat;
      }
      &-de {
        background: #fff url('/static/magazine/background-de.webp') center
          center/cover no-repeat;
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      row-gap: 46px;
      @media (min-width: map-get($map: $grid-breakpoints, $key: md )) {
        row-gap: 68px;
      }
    }
  }

  &-contact-container {
    grid-column: 1/2;
    padding-bottom: 150px;
    @media (min-width: map-get($map: $grid-breakpoints, $key: md)) {
      grid-column: 1/3;
    }
    @media (max-width: map-get($map: $grid-breakpoints, $key: md)) {
      flex-direction: column;
      align-items: center;
    }
    @media (min-width: map-get($map: $grid-breakpoints, $key: xxl)) {
      grid-column: 1/4;
    }
  }
  &-footer {
    &-section {
      background-color: rgba(251, 251, 251, 1);
    }
  }
}
