.rules {
    .lvl-1 {
        li {
            font-weight: 700;
            font-size: 21px;
            margin-bottom: 10px;
        }
    }

    .lvl-2 {
        li {
            font-weight: 300;
            font-size: 16px;
            list-style-type: lower-latin;
            margin-bottom: 5px;
        }
    }

    .lvl-3 {
        li {
            list-style-type: upper-roman;
            margin-bottom: 0px;
        }
    }

    .lvl-4 {
        li {
            list-style-type: circle;
        }
    }
}

.new-rules {
    padding-top: 3rem;
    padding-bottom: 2rem;
    margin-bottom: 80px;

    h2,
    h3 {
        text-align: center;
    }

    &>ol>li>ol {
        list-style-type: lower-latin;
    }

    ul {
        list-style-type: "- ";
        padding-left: 2rem;
    }

    a {
        color: $darkModeOrange;
    }
    
}
