.section-divider {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url('/public/static/SectionBg.png');

  .section-article {
    &_title {
      font-size: 17px;
      color: $white;
      margin-top: 32px;
      margin-bottom: 100px;
    }
  }
  .section-title {
    padding: 75px 0;
  }
}

.section-article--slick {
  @include slick($bottom: 15px);
  .slick-slide {
    padding: 0;
  }
  .section-article {
    &_title {
      padding: 0 0 20px 0;
    }
  }
}

@media (max-width: map-get($map: $grid-breakpoints, $key: md)) {
  .section-divider {
    .section-title {
      padding: 50px 0;
      font-size: 19px;
    }
    .section-article {
      &_title {
        margin-bottom: 60px;
      }
    }
  }
}
