.react-date-picker {
    .react-date-picker__inputGroup {
        &__input {
            width: 30px !important;
            border: none;
            padding: 0;
        }
        &__year {
            width: 50px !important;
        }
        &__divider {
            display: none;
        }
    }
    .react-date-picker__wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        padding: 12px;
        border: 1px solid #dde4f0;
        height: 30px;
    }
    .react-date-picker__inputGroup {
        input:first-of-type {
            border: none;
        }
    }
    button {
        border: none;
        background: none;
    }
    // react-date-picker__calendar
    .react-date-picker__calendar--open {
        background: #fff;
        box-shadow: 0 2px 5px -3px #000;
        padding: 12px;
        border: 1px solid #dde4f0;
        z-index: 1;
    }
    .react-date-picker__calendar--closed {
        display: none;
    }
}
