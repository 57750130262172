.newsletter-no-signon-component {
  width: 100%;
  height: 384px;
  background-image: url('/static/newsletterBg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  white-space: pre-line;
  border-style: solid;
  border-color: #015C92;
  border-width: 7px;
  border-radius: 5px;

  &_title {
    color: $white;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
  }

  &_description {
    margin: 22px 25%;
    color: $white;
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }

  &_button {
    display: flex;
    padding: 13px 16px;
    background-color: $white;
    color: #002D48;
    border-radius: 40px;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
  }
}

@media (max-width: map-get($grid-breakpoints, xxxl)) and (min-width: map-get($grid-breakpoints, xxl)) {
  .newsletter-no-signon-component {
    &_description {
      margin: 20px 20%;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, xxl)) and (min-width: map-get($grid-breakpoints, xl)) {
  .newsletter-no-signon-component {
    &_title {
      font-size: 28px;
      line-height: 32px;
    }

    &_description {
      margin: 20px 15%;
      font-size: 22px;
      line-height: 24px;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, xl)) and (min-width: map-get($grid-breakpoints, lg)) {
  .newsletter-no-signon-component {
    &_title {
      font-size: 26px;
    }

    &_description {
      margin: 20px 10%;
      font-size: 20px;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, lg)) and (min-width: map-get($grid-breakpoints, md)) {
  .newsletter-no-signon-component {
    &_title {
      font-size: 24px;
    }

    &_description {
      margin: 18px 5%;
      font-size: 18px;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, md)) and (min-width: map-get($grid-breakpoints, sm)) {
  .newsletter-no-signon-component {
    height: 300px;
    border-width: 4px;

    &_title {
      font-size: 18px;
    }

    &_description {
      margin: 18px 5%;
      font-size: 14px;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  .newsletter-no-signon-component {
    height: 300px;
    border-width: 4px;

    &_title {
      margin: 0px 16px;
      font-size: 18px;
      line-height: 22px;
    }

    &_description {
      margin: 16px 5%;
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.newsletter-signon-component {
  width: 100%;
  min-height: 500px;
  background-image: url('/static/newsletterBg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px;
  border-style: solid;
  border-color: #015C92;
  border-width: 7px;
  border-radius: 5px;

  &_left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    white-space: pre-line;
    border-right-style: solid;
    border-right-color: #015C92;
    border-right-width: 7px;
  }

  &_right {
    background-color: $white;
    color: #002D48;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    white-space: pre-line;
  }

  &_form {
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    white-space: pre-line;
  }

  &_title {
    margin: 10px 15%;
    color: $white;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
  }

  &_description {
    margin: 10px 10%;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }

  &_email {
    margin: 10px 0px 3px 0px;
    width: 350px;
    text-align: center;
  }

  &_occupation {
    margin: 3px 0px 10px 0px;
    width: 350px;
    text-align: center;
  }

  &_agreements {
    margin: 10px 5%;
  }

  &_expandable {
    text-align: center;
  }

  &_button {
    display: flex;
    margin: 0px 0px 15px 0px;
    padding: 13px 16px;
    background-color: #015C92;
    color: $white;
    border-radius: 40px;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
  }

  &_button:hover {
    color: $white;
  }
}

@media (max-width: map-get($grid-breakpoints, xxl)) and (min-width: map-get($grid-breakpoints, xl)) {
  .newsletter-signon-component {
    &_title {
      font-size: 28px;
      line-height: 32px;
    }

    &_description {
      margin: 20px 5%;
      font-size: 20px;
      line-height: 22px;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, xl)) and (min-width: map-get($grid-breakpoints, lg)) {
  .newsletter-signon-component {
    &_title {
      font-size: 22px;
    }

    &_description {
      margin: 20px 5%;
      font-size: 18px;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, lg)) and (min-width: map-get($grid-breakpoints, md)) {
  .newsletter-signon-component {
    display: flex;
    grid-template-columns: none;
    grid-gap: none;
    border-width: 4px;
    border-radius: 5px;

    &_left {
      display: none;
    }

    &_description {
      margin: 18px 5%;
      font-size: 18px;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, md)) and (min-width: map-get($grid-breakpoints, sm)) {
  .newsletter-signon-component {
    grid-template-columns: auto;
    border-width: 4px;
    border-radius: 5px;

    &_left {
      display: none;
    }

    &_title {
      font-size: 18px;
    }

    &_description {
      margin: 18px 5%;
      font-size: 14px;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  .newsletter-signon-component {
    grid-template-columns: auto;
    border-width: 4px;
    border-radius: 5px;

    &_left {
      display: none;
    }

    &_title {
      margin: 0px 16px;
      font-size: 18px;
      line-height: 22px;
    }

    &_description {
      margin: 16px 5%;
      font-size: 12px;
      line-height: 20px;
    }
  }
}