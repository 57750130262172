.search-page {
  &__post {
    &_title {
      font-size: 22px;
      line-height: 1.1;
      font-weight: 600;
      margin-bottom: 10px;
      em {
        background-color: $textColor;
      }
    }
    &_text {
      font-style: italic;
      line-height: 1.2;
      em {
        font-weight: 600;
        background-color: $textColor;
        padding: 0 2px;
        display: inline-block;
      }
    }
    &_tags {
      margin-top: 5px;
      span {
        border: 1px solid #eee;
        border-radius: 2px;
        padding: 2px 4px;
        margin-right: 2px;
        background: #fefefe;
      }
      em {
        font-weight: 600;
        background-color: $textColor;
        padding: 0 2px;
        display: inline-block;
      }
    }
  }
  &_sortby {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    a {
      border: none;
      background: transparent;
      padding: 0;
      margin-left: 7px;
      transition: 0.3s color;
      &:hover {
        color: $textColor;
      }
      &.active {
        text-decoration: underline;
      }
    }
  }

  &_suggestions {
    a {
      color: $premium500;
      display: inline-block;
      padding: 0 6px;
    }
  }
}
