.tags-recommend {
  background-color: #002d48;
  color: #fff;
  font-weight: 700;
  padding: 4px 8px;
  border-radius: 8px 8px 0 0;
}

.tags-recommend:hover {
  text-decoration: underline;
  color: rgb(255 221 0);
}

.recommended-article {
  background: $lightGray;
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid transparent;
  border-radius: 15px;

  &_tagged {
    border-radius: 0 15px 15px 15px;
  }

  &_photo {
    min-width: 222px !important;
    height: max-content;
  }

  &_text {
    padding: 0 0 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 600;
    overflow: hidden;
    width: 100%;

    .author-intro-wrapper {
      margin-top: 2px;
    }

    .author-info {
      margin-left: 6px;
    }

    .author-info_name,
    .author-info_caption {
      line-height: 1.2;
      font-weight: 300;
    }

    &-title {
      font-size: 16px;
      line-height: 1.17;
      margin-top: -3px;
    }
  }
}

.recommended-article.slick {
  flex-direction: column;

  .recommended-article_text {

    &-title {
      margin-bottom: 14px;
      margin-top: 14px;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, xxl)) {
  .recommended-article {
    padding: 20px;

    &_photo {
      min-width: calc(130px + 5vw) !important;
    }

    &_text {
      padding: 0 0 0 20px;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, xl)) {
  .recommended-article {
    padding: 10px;

    &_text {
      padding: 0 0 0 10px;
      line-height: 1.25;
      font-size: 13px;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, md)) {
  .recommended-article {
    &_text {
      font-size: 14px;
    }
  }
}