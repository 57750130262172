.tag-header {
    text-align: center;
    margin: 80px 0 60px 0;
    p {
        margin: 0;
    }
    &_intro {
        font-size: 17px;
    }
    &_title {
        font-size: 29px;
        font-weight: 600;
    }
}