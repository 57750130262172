.authors-section {
  display: flex;
  flex-direction: row;
  &_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 42px;
    .name {
      font-weight: 600;
      font-size: 17px;
      color: $textDark;
    }
    .caption {
      font-size: 13px;
      color: #8c8c8c;
    }
  }
}

.authors-section_wrapper {
  border: 1px solid $lightGray;
  border-radius: 20px;
  padding: 0 32px;
  position: relative;
  .author-arrows {
    position: absolute;
    top: -41px;
    right: -12px;

    .author-arrow {
      border: none;
      background: none;
      box-shadow: unset;
      font-size: 20px;
      line-height: 1;
      &.disabled {
        visibility: hidden;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, xxl )) {
  .authors-section {
    &_info {
      margin-left: 10px;
    }
    .medium {
      width: 66px !important;
      min-width: 66px !important;
      height: 66px !important;
    }
  }
}
