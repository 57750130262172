.avatar-change {
    background: #fff;
    width: clamp(320px, 80vw, 500px);
    height: min-content;

    &-header {
        text-align: center;
        padding: 30px 10px;
    }
    .avatar-form {
        display: flex;
        // justify-content: ;
        flex-direction: column;
    }
    .avatar-cropper {
        margin: auto;
    }
}