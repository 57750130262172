.contact {
  &-head {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: #5d7697;
    p {
      font-size: 19px;
    }
    &_text {
      color: white;
      padding: 3.8vw 20px 3.8vw 40px;
      position: relative;
      text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
      width: 400px;
    }
    &_image {
      width: calc(100% - 300px);
      background-image: url('../../public/static/contact.webp');
      background-position: 50% 10%;
      background-size: cover;
      background-repeat: no-repeat;
      transform: scale(90%);
    }
  }
  &__tooltip.show {
    max-width: 500px;
    opacity: 1 !important;
  }

  &-info {
    &-text {
      display: none;
    }
  }
  @media only screen and (min-width: 768px) {
    &-info {
      &-text {
        display: block;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints , xxl )) {
  .contact {
    &-head {
      &_text {
        padding: 20px;
        width: 320px;
      }
      &_image {
        width: 100%;
        background-image: url('../../public/static/contact.webp');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
}
@media (max-width: map-get($grid-breakpoints , xl )) {
  .contact {
    &-head {
      // height: 500px;
      flex-direction: column;
      // justify-content: flex-start;
      &_text {
        padding: 20px;
        width: 100%;

        // height: 200px;
      }
      &_image {
        width: 100%;
        height: 300px;
        background-image: url('../../public/static/contact.webp');
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
}

// @media (max-width: map-get($grid-breakpoints , lg )) {
//   .contact {
//     &-head {
//       background-image: url("../../public/static/contact.webp");
//       background-size:  100%;
//     }
//   }
// }

// @media only screen and (max-width: 768px) {
//   .contact {
//     &-head {
//       background-color: #5d7697;
//       background-image: url('/static/img/contact_sm.png');
//       background-position: center 25%;
//       background-size: cover;
//       background-repeat: no-repeat;
//       color: white;
//       padding: 60px 20px;
//       position: relative;
//       &:before {
//         content: '';
//         background: rgba(#223144, 0.61);
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 410px) {
//   .contact {
//     &-head {
//       background-color: #5d7697;
//       background-image: url('/static/img/contact_sm.png');
//       background-position: center 25%;
//       background-size: cover;
//       background-repeat: no-repeat;
//       color: white;
//       padding: calc(20px + 2vh) 20px;
//       position: relative;
//       &:before {
//         content: '';
//         background: rgba(#223144, 0.61);
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//       }
//     }
//   }
// }
.no-padding-x {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
