.article-cover {

  &_footer {
    background: linear-gradient(
      rgba(0, 0, 0, 0) 5%,
      rgba(0, 0, 0, 0.6) 40%,
      rgba(0, 0, 0, 0.8) 95%
    );
  }
}
