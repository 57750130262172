@mixin textShine($color: rgba(0, 0, 0, 0.7), $colorSecond: #000, $time: 2s) {
  &:hover {
    -webkit-mask-image: linear-gradient(
      -75deg,
      $color 30%,
      $colorSecond 50%,
      $color 70%
    );
    -webkit-mask-size: 200%;
    animation: shine $time infinite;
  }
}

.pointer {
  cursor: pointer;
}

@mixin slick($bottom: 10px) {
  .slick-dots {
    position: absolute;
    bottom: $bottom;
    li {
      margin: 0;
      button {
        border-radius: 9999px;
        background: $white;
        width: 13px;
        height: 13px;
        &:before {
          display: none;
        }
      }
      &.slick-active {
        button {
          background: $darkModeOrange;
        }
      }
    }
  }
}

@mixin zoomable($time: 3s) {
  .zoomable {
    transform: scale(1);
    transition: transform $time;
  }
  &:hover {
    .zoomable {
      transform: scale(1.1);
    }
  }
}

@keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }
  to {
    -webkit-mask-position: -50%;
  }
}
