.social-auth-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    .social-item {
        min-width: 110px;
        width: 32%;
        margin-bottom: 10px;
        .btn {
            color: #fff;
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 14px;
            svg,
            img {
                margin-right: 5px;
            }
        }
        &.fb {
            .btn {
                background: $fb;
            }
        }
        &.google {
            .btn {
                background: $google;
            }
        }
        &.li {
            .btn {
                background: $li;
            }
        }
        &.oneLogin {
            .btn {
                background: $oneLogin;
            }
        }
    }
}

@media (max-width: map-get($grid-breakpoints, md )) {
    .social-auth-wrapper {
        justify-content: center;
        .social-item {
            width: 40%;
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}
