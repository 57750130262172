$imageVal: -100px;

.newsletter-link {
  color: #d14900;
}

.newsletter-page {
  &_header {
    display: flex;
    min-height: 580px;
    background: url('/static/newsletter/bg.jpg');
    background-size: cover;
    align-items: center;
    height: calc(100vh - 95px);
  }
  &_cover {
    position: relative;
    .image {
      position: absolute;
    }
    .image--1 {
      right: calc(50px + #{$imageVal}); //3
      z-index: 2;
      top: 40px;
    }
    .image--2 {
      right: calc(230px + #{$imageVal}); //1
      z-index: 3;
    }
    .image--3 {
      right: calc(160px + #{$imageVal});
      z-index: 1;
      top: 10px;
    }
  }

  &_form {
    color: #fff;
    &--header {
      margin: 0;
      font-size: 25px;
      font-weight: 600;
    }
    &--description {
      font-size: 21px;
    }
  }
}

@media (max-width: map-get($map: $grid-breakpoints, $key: lg )) {
  .newsletter-page {
    &_cover {
      transform: scale(0.8);
      .image--1 {
        right: -100px; //3
      }
      .image--2 {
        right: 80px; //1
      }
      .image--3 {
        right: 10px;
      }
    }
  }
}

@media (max-width: map-get($map: $grid-breakpoints, $key: md )) {
  .newsletter-page {
    &_header {
      height: auto;
    }
    &_cover {
      transform: scale(0.5);
      .image--1 {
        right: -160px; //3
      }
      .image--2 {
        right: -110px; //1
      }
      .image--3 {
        right: -130px;
      }
    }
  }
}

@media (max-width: map-get($map: $grid-breakpoints, $key: sm )) {
  .newsletter-page {
    &_header {
      min-height: 700px;
      height: auto;
    }
    &_cover {
      transform: unset;
      display: none;
      .image {
        transform: scale(0.9);
      }
      .image--1 {
        right: 0px; //3
      }
      .image--2 {
        right: 50px; //1
      }
      .image--3 {
        right: 30px;
      }
    }
  }
}
