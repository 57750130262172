$lightOrange: #fce7de;
$orange: #f08559;
$darkOrange: #b9461b;
$teal: #395778;
$darkTeal: #1f2e3e;
$lightgray: #f9f9f9;
$gray: #737373;

.webinar {
  &-see-more {
    padding: 30px 0 150px;
    @media (max-width: map-get($map: $grid-breakpoints, $key: md)) {
      padding: 30px 0 200px;
    }
    @media (max-width: map-get($map: $grid-breakpoints, $key: sm)) {
      padding: 30px 0 250px;
    }
  }
  &-button {
    padding: 14px 80px;
    border-radius: 14px;
    border: none;
    font-size: 20px;
    font-weight: 600;
    color: white;
    background: $darkTeal;
    background: -moz-linear-gradient(90deg, $darkTeal 0%, $teal 100%);
    background: -webkit-linear-gradient(90deg, $darkTeal 0%, $teal 100%);
    background: linear-gradient(90deg, $darkTeal 0%, $teal 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1f2e3e", endColorstr="#395778", GradientType=1);
    &:hover {
      color: white;
      background: $darkOrange;
      background: -moz-linear-gradient(90deg, $darkOrange 0%, $orange 100%);
      background: -webkit-linear-gradient(90deg, $darkOrange 0%, $orange 100%);
      background: linear-gradient(90deg, $darkOrange 0%, $orange 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b9461b", endColorstr="#f08559", GradientType=1);
    }
  }
  &-divider {
    border-bottom: 1px solid $gray;
    width: 30%;
  }
  &-title {
    width: max-content;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 70px;
    border-radius: 50px;

    font-size: 22px;
    font-weight: 700;
    @media (max-width: map-get($map: $grid-breakpoints, $key: lg)) {
      padding: 17px 16px;
      font-size: 16px;
    }
  }
  &-header {
    padding-top: 60px;
    padding-bottom: 155px;
    position: relative;

    &-strap {
      position: relative;
      height: 135px;
      background-color: $orange;
      border-radius: 0 70px 70px 0;
      padding-right: 55px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 80px;
      font-weight: 600;
      color: white;
      margin-right: 35px;
      min-width: max-content;

      @media (max-width: 1600px) {
        font-size: 74px;
      }

      @media (max-width: map-get($map: $grid-breakpoints, $key: xxl)) {
        font-size: 60px;
      }

      @media (max-width: map-get($map: $grid-breakpoints, $key: xl)) {
        font-size: 45px;
        height: 110px;
      }

      &::before {
        content: '';
        left: -100%;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: $orange;
        z-index: 10;
        position: absolute;
      }
    }

    &-trans-logo {
      padding-right: 55px;
      // aspect-ratio: 21/3;
      object-fit: contain;
      @media (max-width: map-get($map: $grid-breakpoints, $key: lg)) {
        padding-left: 50px;
        padding-right: 50px;
        // aspect-ratio: 26/3;
      }
    }
    &-line {
      position: absolute;
      left: 40%;
      bottom: 0;
      transform: translate(-40%, -20%);
      @media (max-width: map-get($map: $grid-breakpoints, $key: xxl)) {
        left: 40%;
        bottom: 0;
        transform: translate(-55%, -20%);
      }
      @media (max-width: map-get($map: $grid-breakpoints, $key: xl)) {
        left: 40%;
        bottom: 0;
        transform: translate(-65%, -20%);
      }
      @media (max-width: map-get($map: $grid-breakpoints, $key: lg)) {
        display: none;
      }
    }

    &-info {
      padding-right: 55px;
      @media (max-width: map-get($map: $grid-breakpoints, $key: lg)) {
        padding-left: 50px;
        padding-right: 50px;
        margin-bottom: 30px;
      }
    }

    &-hero {
      border: 1px solid $lightOrange;
      padding: 0;
      border-radius: 30px;
      background: $lightgray;
      box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 25%);
      min-height: 400px;

      &-first {
        padding: 40px 60px 24px;
        text-align: center;
        @media (max-width: map-get($map: $grid-breakpoints, $key: lg)) {
          padding: 40px 20px 24px;
        }

        h2 {
          font-size: 20px;
          color: $teal;
          font-weight: 700;
        }

        h3 {
          font-size: 16px;
          font-weight: 200;
        }

        a {
          color: $orange;
        }
      }

      &-gradient {
        padding: 20px 0;
        background: $orange;
        background: -moz-linear-gradient(90deg, $orange 0%, $darkOrange 100%);
        background: -webkit-linear-gradient(
          90deg,
          $orange 0%,
          $darkOrange 100%
        );
        background: linear-gradient(90deg, $orange 0%, $darkOrange 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ef8458", endColorstr="#b9461b", GradientType=1);
        &-date {
          width: max-content;
          @media (max-width: map-get($map: $grid-breakpoints, $key: lg)) {
            width: 25%;
            min-width: max-content;
          }
        }
        img {
          max-width: 35px;
          @media (max-width: map-get($map: $grid-breakpoints, $key: lg)) {
            width: 25px;
          }
        }

        &-nowebinar {
          min-width: 0 !important;
        }

        p {
          color: white;
          margin: 0;
          min-width: max-content;
        }
      }

      &-people {
        padding: 60px;

        img {
          width: 120px;
          height: 120px;
          aspect-ratio: 1;
          border-radius: 50%;
          border: 1px solid #e2e2e2;
          object-fit: cover;
        }

        h5,
        p {
          margin: 0;
        }

        &-divider {
          height: 100%;
          border-left: 1px solid $teal;
          border-bottom: none;

          @media (max-width: map-get($map: $grid-breakpoints, $key: xl)) {
            height: 10%;

            width: 50%;
            border-bottom: 1px solid $teal;
            border-left: none;
          }
        }

        &-presenter {
          min-width: 110px;
          h4 {
            margin-bottom: 2px;
            font-size: 14px;
            font-weight: 700;
            color: $gray;
          }

          h5 {
            font-size: 14px;
            font-weight: 600;
            color: $orange;
          }

          p {
            font-size: 10px;
            font-weight: 400;
            color: $orange;
          }
        }

        &-prelegent {
          color: $gray;

          h4 {
            margin-bottom: 2px;
            font-size: 14px;
            font-weight: 700;
          }

          p,
          li {
            font-size: 14px;
            font-weight: 300;
          }
          ul {
            list-style: none;
          }
        }
        @media (max-width: map-get($map: $grid-breakpoints, $key: xl)) {
          padding: 60px 30px;
        }
        @media (max-width: map-get($map: $grid-breakpoints, $key: lg)) {
          padding: 22px 30px 44px;
        }
      }
      button {
        margin: 10px 0 45px 0;
        @media (max-width: map-get($map: $grid-breakpoints, $key: lg)) {
          padding: 14px 64px;
        }
      }

      @media (max-width: map-get($map: $grid-breakpoints, $key: lg)) {
        margin: 0 50px;
      }
    }
    @media (max-width: map-get($map: $grid-breakpoints, $key: lg)) {
      padding-top: 89px;
      padding-bottom: 97px;
    }
  }

  &-numbers {
    padding: 132px 0 120px 0;
    position: relative;

    &-bg {
      background-color: $lightOrange;
    }

    &-title {
      border: 2px solid $orange;
      background-color: white;
      color: $teal;
    }
    @media (max-width: map-get($map: $grid-breakpoints, $key: lg)) {
      padding: 85px 0 40px;
    }
  }
  &-list {
    position: relative;
    padding: 80px 0 50px;
    row-gap: 60px;
    &-title {
      background-color: $orange;
      color: white;
      border: 3px solid white;
    }
    @media (max-width: map-get($map: $grid-breakpoints, $key: lg)) {
      row-gap: 110px;
      padding: 30px 0 30px;
    }
  }
  &-contact {
    &-info {
      position: absolute;
      top: 0;
      left: 50%;
      border: 3px solid $lightgray;
      border-radius: 20px;
      transform: translate(-50%, -50%);
      padding: 20px;
      background-color: white;
      color: $gray;
      line-height: 21px;
      font-size: 16px;
      text-align: center;
    }
    &-bg {
      position: relative;
      background-color: $lightgray;
    }
    &-container {
      padding: 125px 0 150px;
    }
    @media (max-width: map-get($map: $grid-breakpoints, $key: lg)) {
      &-bg {
        background-color: white;
      }
      &-container {
        padding: 150px 0 150px;
      }
    }
    @media (max-width: map-get($map: $grid-breakpoints, $key: md)) {
      &-container {
        padding: 250px 0 150px;
      }
    }
    @media (max-width: map-get($map: $grid-breakpoints, $key: sm)) {
      &-container {
        padding: 300px 0 150px;
      }
    }
  }
  &-footer-section {
    padding-top: 80px;
    padding-bottom: 80px;
    @media (max-width: map-get($map: $grid-breakpoints, $key: lg)) {
      padding-top: 40px;
      padding-bottom: 150px;
    }
  }
}
