.login {
  background: #fff;
  width: clamp(320px, 80vw, 600px);
  height: min-content;
  border-radius: 20px;
  &-header {
    text-align: center;
    padding: 30px 10px;
  }
  &-part {
    padding: 15px 40px;
    background: #f8f8f8;
  }
  &-footer {
    padding: 20px 40px;
    &_text {
      font-size: 19px;
      text-align: center;
      font-weight: 600;
    }
    .register-btn {
      border: 2px solid #dde4f0;
      text-decoration: none;
      color: #49596a;
      padding: 15px 20px;
      width: 100%;
      font-size: 18px;
    }
  }
}
