.category-tile {
  font-size: 16px;
  &_wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &_box {
    border: 2px solid #999;
    margin-right: 5px;
    padding: 10px;
    flex-grow: 1;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
  }
  .category-info {
    line-height: 1.15;
    min-height: 50px;
  }
  .rodo {
    font-size: 13px;
    margin-bottom: 8px;
  }
}
