@import 'styles/_variables.scss';

.wrapper {
  position: fixed;
  inset: 0;
  z-index: 100;
  background-color: #00000055;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup {
  position: relative;
  max-height: 90vh;
  max-width: 1200px;
  overflow: hidden;

  &.img img {
    width: max-content;
    height: auto;
    max-height: 90vh;
  }

  .close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    right: 10px;
    cursor: pointer;
    svg {
      flex-shrink: 0;
    }
  }
}
