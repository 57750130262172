@mixin headerBg($url: '/static/category/transport.jpg') {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url($url);
  background-position: center -100px;
  background-size: cover;
  background-attachment: fixed;

  @media (max-width: map-get($grid-breakpoints, xl )) {
    background-position: center center;
  }
}

.category-header {
  // padding-bottom: 50px;
  @include headerBg;
  &.transport {
    @include headerBg($url: '/static/category/transport.jpg');
  }
  &.logistics {
    @include headerBg($url: '/static/category/logistic.jpg');
  }
  &.trucking {
    @include headerBg($url: '/static/category/trucking.jpg');
  }
  &.shipment {
    @include headerBg($url: '/static/category/shipment.jpg');
  }
  &.interviews {
    @include headerBg($url: '/static/category/interviews.jpg');
  }
  &.video {
    @include headerBg($url: '/static/category/video.jpg');
  }

  &_title {
    margin: 70px 0 40px 0;
  }
  .side-col {
    display: flex;
    flex-direction: column;
    gap: 50px;
    height: 100%;
    justify-content: space-between;
  }

  .right-0 {
    margin-right: 0px !important;
    padding-right: 0px !important;
  }

  .article-info_title {
    font-size: 21px;
    font-weight: 600;
    color: $white;
    margin-bottom: 0.7vw;
  }
}

@media (max-width: map-get($grid-breakpoints, xl )) {
  .category-header {
    .article-info_title {
      font-size: 17px;
      line-height: 1.25;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, md )) {
  .category-header {
    min-height: 100vh;
    &_title {
      margin: 20px 0 20px 0;
    }
    .article-info_title {
      margin-bottom: 14px;
    }
    .article-info {
      margin-bottom: 20px;
    }
  }
}
