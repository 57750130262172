// ol, ul {
//     list-style: none;
//     padding: 0;
//   }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  // Clearfix
  
  .cf:before,
  .cf:after {
    content: " ";
    display: table;
  }
  
  .cf:after {
    clear: both;
  }
  
  .cf {
    *zoom: 1;
  }