.popup-background {
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  @include z-index('popup');
  // align-items: center;
  padding: 10vh 10px;
  .popup {
    position: relative;
    overflow-y: auto;
  }
  .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    opacity: 100%;
    transition: opacity 1s;
    &:disabled {
      opacity: 0;
      cursor: auto;
    }
  }
}
