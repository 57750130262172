.newsletter-subscribe-popup {
  // background: #fff;

  background: linear-gradient(
      72deg,
      rgba($oldBrandCol2, 0.14) 20%,
      rgba($oldBrandCol2, 0) 20%
    ),
    linear-gradient(
      72deg,
      rgba($oldBrandColor3, 1) 40%,
      rgba($oldBrandColor3, 0) 40%
    ),
    linear-gradient(
      72deg,
      rgba(255, 255, 255, 0.02) 85%,
      rgba($oldBrandColor3, 0) 85%
    ),
    linear-gradient(
      to bottom,
      rgba(55, 81, 108, 1) 0%,
      rgba(55, 81, 108, 1) 100%
    );
  padding: 20px;
  color: #fff;
  width: clamp(300px, 50vw, 400px);
  box-shadow: 0 1px 4px -3px #000;
  // position: absolute;
  // left: 10px;
  // bottom: 90px;
  .newsletter-sub__title {
    font-size: 27px;
  }
}

.unsubscribe-success {
  background: #fff;
  width: clamp(320px, 80vw, 1000px);
  border-top: 5px rgba(55, 81, 108, 1) solid;
  border-radius: 15px;
  height: min-content;
  padding: 24px 40px;
  font-size: 16px;
}
