

.checkbox {
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  border: 1px solid currentColor;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 5px;
  transition: background-color 240ms ease-in-out;
}

.checkbox::before {
  content: "";
  width: 1em;
  height: 1em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 180ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: white;
}
.checkbox:checked {
  background-color: $premiumDark;
}

.checkbox:checked::before {
  transform: scale(1);
}


.checkbox:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}
.checkbox:not(:disabled) {
  cursor: pointer;
}

.radio {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;

  font: inherit;
  border: 1px solid currentColor;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  transition: background-color 240ms ease-in-out;
}

.radio::before {
  content: "";
  width: 1em;
  height: 1em;
  border-radius: 50%;
  transform: scale(0);
  transform-origin: center;
  transition: 180ms transform ease-in-out;
  box-shadow: inset 0.5em 0.5em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: currentColor;
}

.radio:checked::before {
  transform: scale(1);
}

.radio:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.radio:not(:disabled) {
  cursor: pointer;
}

