html {
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
}

body {
  // padding-top: $headerHeight;
  background-color: #fff;
  // font-family: $font-family-sans-serif;
  min-height: 100vh;
  height: auto;
  --background-color: #fff;
}

body:has(.darkmode) {
  background-color: $darkModeBackground;
  --background-color: #{$darkModeBackground};
}

body.info-bar {
  .page-layout-wrapper {
    padding-top: $headerHeight;

    @media (max-width: map-get($map: $grid-breakpoints, $key: lg)) {
      padding-top: calc(#{$headerHeightMobile} + #{$headerInfoBarHeight} + 10px);
    }
  }
}

.white-space-no-wrap {
  white-space: nowrap !important;
}

// .container,
// .container-fluid {
//   padding-right: 0px;
//   padding-left: 0px;
// }

.page-layout-wrapper {
  padding-top: $headerHeight;
  min-height: calc(100vh - #{$headerHeight} - 40px);

  @media (max-width: map-get($map: $grid-breakpoints, $key: lg)) {
    padding-top: calc(#{$headerHeightMobile} + 10px);
  }
}

ul {
  list-style: none;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-13,
.col-14,
.col-15,
.col-16,
.col-17,
.col-18,
.col-19,
.col-20,
.col-21,
.col-22,
.col-23,
.col-24,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-13,
.col-sm-14,
.col-sm-15,
.col-sm-16,
.col-sm-17,
.col-sm-18,
.col-sm-19,
.col-sm-20,
.col-sm-21,
.col-sm-22,
.col-sm-23,
.col-sm-24,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-13,
.col-md-14,
.col-md-15,
.col-md-16,
.col-md-17,
.col-md-18,
.col-md-19,
.col-md-20,
.col-md-21,
.col-md-22,
.col-md-23,
.col-md-24,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-13,
.col-lg-14,
.col-lg-15,
.col-lg-16,
.col-lg-17,
.col-lg-18,
.col-lg-19,
.col-lg-20,
.col-lg-21,
.col-lg-22,
.col-lg-23,
.col-lg-24,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-13,
.col-xl-14,
.col-xl-15,
.col-xl-16,
.col-xl-17,
.col-xl-18,
.col-xl-19,
.col-xl-20,
.col-xl-21,
.col-xl-22,
.col-xl-23,
.col-xl-24,
.col-xl,
.col-xl-auto {
  padding-right: 10px;
  padding-left: 10px;
}

a {
  text-decoration: none;
  color: unset;

  &:hover {
    color: unset;
  }
}

.link {
  text-decoration: underline;
}

.link-color {
  color: $darkModeOrange;
}


@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.9);
    opacity: 0.7;
  }
}

$spaceamounts: (
  1,
  2,
  3,
  4,
  5,
  10
); // Adjust this to include the pixel amounts you need.
$sides: (
  left,
  right
); // Leave this variable alone

.cmplazypreviewiframe {
  max-width: 100%;
}

// is embed-container-*-responsive
[class*="embed-container-"][class*="-responsive"] iframe {
  width: 100%;
}

.embed-container-responsive:has(iframe[data-cmp-activated]) {
  position: relative;
  overflow: hidden;
  width: 100% !important;
  padding-top: 100% !important;
}

.embed-container-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

.embed-container-youtube-responsive:has(iframe[data-cmp-activated]) {
  position: relative;
  overflow: hidden;
  width: 100% !important;
  padding-top: 56.25% !important;
}

.embed-container-youtube-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

.embed-container-linkedin-responsive:has(iframe[data-cmp-activated]) {
  position: relative;
  overflow: hidden;
  width: 100% !important;
  padding-top: 100% !important;
}

.embed-container-linkedin-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

.embed-container-facebook-responsive:has(iframe[data-cmp-activated]) {
  display: block;
  text-align: center;
}

.embed-container-facebook-responsive iframe {
  margin: auto;
  display: block;
  overflow: hidden;
  min-height: 490px;
}

.horizontal-video {
  iframe {
    height: 100% !important;
    width: auto !important;
  }

  .embed-responsive {
    padding-top: 55% !important;

    @media (max-width: map-get($map: $grid-breakpoints, $key: xxl)) {
      padding-top: 75% !important;
    }

    @media (max-width: map-get($map: $grid-breakpoints, $key: xl)) {
      padding-top: 75% !important;
    }

    @media (max-width: map-get($map: $grid-breakpoints, $key: lg)) {
      padding-top: 80% !important;
    }

    @media (max-width: map-get($map: $grid-breakpoints, $key: md)) {
      padding-top: 110% !important;
    }

    @media (max-width: map-get($map: $grid-breakpoints, $key: sm)) {
      padding-top: 150% !important;
    }
  }
}

.fb-video {
  margin: 20px 0;
  width: 100%;
  display: flex !important;
  justify-content: center;
}

.layout {
  &__info-bar-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 45;
  }
}

:root {
  --premium-900: #{$premium900};
  --premium-700: #{$premium700};
  --premium-500: #{$premium500};
  --premium-200: #{$premium200};
  --premium-100: #{$premium100};

  --orange-900: #{$orange900};
  --orange-700: #{$orange700};
  --orange-500: #{$orange500};
  --orange-200: #{$orange200};
  --orange-100: #{$orange100};
}