.btn {
  cursor: pointer;
  border: none;
  border-radius: 10px;
  &.color {
    background: #f87251;
    color: white;
    border: 0;
    transition: background 0.3s;
    &:hover {
      background: darken(#f87251, 5);
    }
  }
  &.bg {
    background: $mainColorLighten;
    color: white;
    border: 0;
    transition: background 0.3s;
    &:hover {
      background: darken($mainColorLighten, 5);
    }
  }
  &.link {
    position: relative;
    color: $mainColor;
    padding: 2px;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: lighten($mainColor, 15);
      bottom: 3px;
      left: 0;
      transition: height 0.1s;
    }
    &:hover {
      text-decoration: none;
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background: lighten($mainColor, 15);
        bottom: 3px;
        left: 0;
      }
    }
  }
}
