.authors-top-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
}

.authors-wrapper {
    background: #F5F5F5;
    &__sort {
        display: flex;
        margin-top: 20px;
        align-items: center;
        &_btns {
            margin-left: 5px;
            button {
                background: none;
                padding: 0 5px;
                border: none;
                margin: 0 5px;
                &.active {
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
        }
        &_selector {
            width: 200px;
            margin-left: 10px;
        }
    }
}

@media (max-width: map-get($grid-breakpoints, xl )) {
    .authors-top-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: map-get($grid-breakpoints, lg )) {
    .authors-top-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: map-get($grid-breakpoints, md )) {
    .authors-top-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: map-get($grid-breakpoints, sm )) {
    .authors-top-grid {
        grid-template-columns:  1fr;
    }
}