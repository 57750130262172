// .slick-slider {

//   .slick-slide {
//     padding: 5px;
//     height: auto;
//   }

//   .slick-track {
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: -webkit-flex;
//     display: flex;
//     -webkit-flex-direction: row;
//     flex-direction: row;
//   }
.recommended-wrapper--slick,
.slick-track,
.slick-slider {
  border-radius: 15px;
}

//   .slick-next:before,
//   .slick-prev:before {
//     font-family: fontAwesome;
//     font-size: 4rem;
//     color: $txtColor;
//   }

//   .slick-prev{
//     left: -30px;
//     &:before {
//       content: "\f104";
//     }
//   }

//   .slick-next {
//     right: -30px;
//     &:before {
//       content: "\f105";
//     }
//   }

//   .slick-dots {
//     position: relative;
//   }

//   // Responsive Styles
//   @media only screen and (min-width: 1350px) {
//     .slick-prev{
//       left: -50px;
//     }

//     .slick-next {
//       right: -50px;
//     }
//   }
//   // Responsive Styles
// }
