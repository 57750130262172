$z-index: (
  popup: 100,
  navigation: 10,
  video: 5,
  footer: 30,
  loader: 25,
  notification: 20,
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key, $indexMod: 0) {
  z-index: z-index($key) + $indexMod;
}
