.featured-content {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem 1rem;

  img {
    display: block;
    width: 60%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
  }
}