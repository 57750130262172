.thumbnail,
.thumbnail-zoomable {
  background-color: $premium100;
  &.small {
    min-width: 34px !important;
    width: 34px !important;
    height: 34px !important;
  }
  &.medium {
    min-width: 88px !important;
    width: 88px !important;
    height: 88px !important;
  }
  &.big {
    min-width: 136px !important;
    width: 136px !important;
    height: 136px !important;
  }
  &.premium {
    min-width: 62px !important;
    width: 62px !important;
    height: 62px !important;
    @media (max-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
      min-width: 31px !important;
      width: 31px !important;
      height: 31px !important;
    }
  }
}

.thumbnail-zoomable {
  overflow: hidden;
  will-change: transform;
  border-radius: 9999px;
  .thumbnail {
    transition: transform 0.3s;
  }
  &:hover {
    .thumbnail {
      transform: scale(1.1);
    }
  }
}
