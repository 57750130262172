.input-group.checkbox {
  display: flex;
  // justify-content: flex-start;
  align-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  .checkbox-input {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    opacity: 0;
  }
  &.small {
    font-size: 13px;
    .label-text {
      margin-top: -2px;
    }
    .checkbox-input {
      width: 14px;
      height: 14px;
    }
    .checkbox-custom {
      width: 14px;
      height: 14px;
      &.checked {
        .tick {
          font-size: 9px;
          margin-bottom: 12px;
          margin-left: 1px;
        }
      }
    }
  }
}

.category-checkbox {
  align-items: center;
}

.checkbox-custom {
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
  &.checked {
    .tick {
      margin-bottom: 6px;
      margin-left: 1px;
    }
  }
}

.input-group.checkbox.color {
  .checkbox-custom {
    border: 2px solid $textColor;
    &.checked {
      .tick {
        color: $textColor;
      }
    }
  }
}
.input-group.checkbox.normal {
  .checkbox-custom {
    border: 2px solid $mainColor;
    &.checked {
      .tick {
        color: $mainColor;
      }
    }
  }
}

.input-group.checkbox.light {
  .checkbox-custom {
    border: 2px solid #eee;
    &.checked {
      .tick {
        color: #eee;
      }
    }
  }
}
