.promoted-wrapper {
  border: 24px solid $orange500;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 93px;
  padding: 5px 0;
  border-radius: 20px;
  .promoted-article {
    position: relative;
    min-height: 230px;
    display: flex;
    align-items: center;
    &_photo {
      position: absolute;
      left: -54px;
      width: clamp(200px, 20vw, 308px) !important;
      @include zoomable($time: 0.5s);
    }
    &_title {
      margin-left: calc(clamp(200px, 20vw, 308px) - 18px);
      padding: 10px 15px;
      font-size: 21px;
      line-height: 1.2;
      font-weight: 600;
      color: $textDark;
    }
  }
  //TOCHANGE Klasa dla 3 promowanych
  &-pl {
    .promoted-article {
      min-height: 120px;
    }
    .promoted-article_photo {
      width: clamp(200px, 20vw, 220px) !important;
    }
    .promoted-article_title {
      margin-left: calc(clamp(200px, 20vw, 220px) - 18px);
    }
  }
  &.side {
    height: min-content;
    border: 16px solid $orange500;
    margin-left: 0px;
    .promoted-article {
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      &_photo {
        position: relative;
        left: unset;
        width: 100% !important;
        padding: 1.5vw 1.5vw 0 1.5vw;
        @include zoomable($time: 0.5s);
      }
      &_title {
        margin-left: 0px;
        padding: 1vw 1.5vw 1.5vw 1.5vw;
        font-size: 21px;
        line-height: 1.35;
        font-weight: 600;
        color: $textDark;
      }
    }
  }
  &.pl {
    border-color: #172e44 !important;
  }
}

@media (max-width: map-get($grid-breakpoints, xxl )) {
  .promoted-wrapper {
    border-width: 20px;
    margin-left: 60px;

    .promoted-article {
      min-height: 160px;
      &_photo {
        width: clamp(200px, 16vw, 300px) !important;
      }
      &_title {
        margin-left: calc(clamp(200px, 16vw, 300px) - 50px);
        padding: 10px;
        font-size: 18px;
        line-height: 1.2;
      }
    }
    //TOCHANGE Klasa dla 3 promowanych
    &-pl {
      .promoted-article {
        min-height: 120px;
      }
      .promoted-article_photo {
        width: clamp(120px, 20vw, 150px) !important;
      }
      .promoted-article_title {
        margin-left: calc(clamp(120px, 20vw, 150px) - 18px);
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, xl )) {
  .promoted-wrapper {
    border-width: 16px;
    margin-left: 40px;

    .promoted-article {
      &_photo {
        left: -60px;
      }
      &_title {
        margin-left: calc(clamp(200px, 16vw, 300px) - 50px);
      }
    }
    //TOCHANGE Klasa dla 3 promowanych
  }
}
@media (max-width: map-get($grid-breakpoints, lg )) {
  .promoted-wrapper {
    //TOCHANGE Klasa dla 3 promowanych
    background-color: white;

    &-pl {
      .promoted-article {
        min-height: 160px;
      }
      .promoted-article_photo {
        width: clamp(200px, 20vw, 400px) !important;
      }
      .promoted-article_title {
        margin-left: calc(clamp(200px, 20vw, 400px) - 18px);
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, md )) {
  .promoted-wrapper {
    border-width: 24px;
    margin-left: 0px;
    padding: 48px;
    .promoted-article {
      display: flex;
      flex-direction: column;
      min-height: 160px;
      &_photo {
        position: relative;
        left: unset;
        width: 100% !important;
      }
      &_title {
        margin-left: unset;
        padding: 20px 0;
        font-size: 18px;
        line-height: 1.35;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, sm )) {
  .promoted-wrapper {
    border-width: 8px;
    padding: 8px;
    &.side {
      border-width: 8px;
      .promoted-article {
        flex-direction: row;
        .promoted-article {
          padding: 10px 0;
          display: flex;
          flex-direction: column;
          &_photo {
            width: 156px !important;
            min-width: 156px !important;
            max-width: 156px !important;
            overflow: hidden;
            height: min-content;
          }
          &_title {
            margin-top: -5px;
            padding-left: 10px;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }

    .promoted-article {
      flex-direction: row;
      .promoted-article {
        padding: 8px 0;
        display: flex;
        flex-direction: column;
        &_photo {
          width: 156px !important;
          min-width: 156px !important;
          max-width: 156px !important;
          overflow: hidden;
          height: min-content;
        }
        &_title {
          margin-top: -5px;
          padding-left: 10px;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}
