.newsletter-divider {
  height: 420px;
  background-image: url('/static/newsletterBg.jpg');
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  white-space: pre-line;
  &_title {
    color: $white;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
  }

  .email-form {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding: 6px;
    width: clamp(272px, 30vw, 460px);
    border-radius: 15px;
    margin-top: 45px;
    white-space: nowrap;
    position: relative;
    .btn {
      height: 56px;
      font-size: 17px;
    }
    .email-input {
      border: none;
      width: 100%;
      height: 33px;
      padding: 0 5px;
      font-size: 17px;
      &:focus {
        outline: none !important;
        border-bottom: 0px solid #fdfdfd;
      }
    }
    .email-button {
      border-radius: 15px;
    }
    .newslleter-form_error {
      position: absolute;
      bottom: -20px;
      color: #fdfdfd;
    }
  }

  &.small {
    background-image: url('/static/newsletterSmall.png');
    background-size: cover;
    padding: 40px 20px;
    line-height: 1.35;
    height: auto;
    .newsletter-divider_title {
      text-align: left;
      font-size: 27px;
    }
    .email-form {
      width: 100%;
      padding: 4px;
      margin-top: 40px;
      margin-bottom: 60px;
      .btn {
        margin: 4px;
        padding: 0 12px;
        height: 42px;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, xl )) and (min-width: map-get($grid-breakpoints, lg )) {
  .newsletter-divider {
    &.small {
      padding: 12px;
      line-height: 1.35;
      height: auto;
      .newsletter-divider_title {
        text-align: left;
        font-size: 25px;
        line-height: 1.25;
      }
      .email-form {
        width: 100%;
      }
    }
  }
}

@media (max-width: map-get($grid-breakpoints, sm )) {
  .newsletter-divider {
    &.small {
      padding: 12px;
      .newsletter-divider_title {
        text-align: left;
        font-size: 27px;
        line-height: 1.25;
      }
      .email-form {
        width: 100%;
      }
    }
    &.main-page {
      height: min-content;
      padding: 24px;
      .newsletter-divider_title {
        font-size: 22px;
        text-align: left;
        font-weight: 500;
        line-height: 1.2;
        margin: 46px 0 60px 0;
      }
      .email-form {
        margin-bottom: 46px;
        margin-top: 0px;
      }
    }
  }
}
