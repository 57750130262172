button,
a {
  cursor: pointer;
}

a.link-white {
  color: #fff;
}
a.link-user {
  font-weight: bold;
}

.underline-link {
  text-decoration: underline;
}
.underline-link-none {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.link {
  cursor: pointer !important;
  text-decoration: none;
  // color: $linkColor;
  &:hover {
    text-decoration: underline;
  }
}