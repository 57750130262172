.poll-popup {
  background: #fff;
  width: clamp(320px, 80vw, 800px);
  border-top: 4px solid $orange;
  height: min-content;
  padding: 24px 40px;
  font-size: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
  border-radius: 5px;
  button {
    background-color: $orange;
    color: white;
    padding: 10px 30px;
    margin: 20px 40px 0;
    border: none;
    border-radius: 5px;
    width: auto;
    opacity: 100%;
    transition: opacity 500ms;
    &:disabled {
      cursor: not-allowed;
      opacity: 40%;
    }
  }
  select {
    background-color: white;
    color: black;
    padding: 5px 20px;
    border: 1px solid gray;
    border-radius: 10px;
  }
  img {
    width: 50%;
    margin-bottom: 10px;
  }
  &-header {
    font-size: 20px;
  }
  &-subheader {
    font-size: 18px;
    font-weight: 600;
  }
  &-option {
    font-size: 16px;
    column-gap: 15px;
  }
  @media (max-width: map-get($map: $grid-breakpoints, $key: md)) {
    button {
      min-width: 75%;
      width: max-content;
    }
    img {
      width: 75%;
      margin-bottom: 15px;
    }
    &-header {
      font-size: 18px;
    }
    &-subheader {
      font-size: 16px;
      margin-bottom: 0;
    }
    &-option {
      font-size: 16px;
      column-gap: 8px;
      margin-bottom: 2px;
    }
  }
}
