.remind-password {
    background: #fff;
    width: clamp(320px, 80vw, 600px);
    height: min-content;

    &-header {
        text-align: center;
        padding: 30px 10px;
    }
    &-part {
        padding: 15px 40px;
        background: #f8f8f8; 
    } 
    &__form-footer {
        display: flex;
        justify-content: center;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    &-footer {
        font-size: 16px;
        text-align: center;
        padding: 30px 40px ;
    }
}